.container-custom {
  overflow-y: scroll;
  height: 70vh;
}
.container::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
.container::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}
.container::-webkit-scrollbar-thumb {
  background-color: rgba(54, 54, 54, 0.466); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid rgba(78, 78, 78, 0.452); /* creates padding around scroll thumb */
}
.inprogress-card {
  background-color: #aad6b9;
  border-radius: 15px;
  height: 90%;
}
.darkCard {
  opacity: 0.9;
}
.topicName {
  color: #333333;
  font-size: 25px;
  font-weight: 700;
}
.totalQuestion {
  color: #4f4f4f;
  font-weight: 700;
}
.notstarted-card {
  background-color: #aad0f6;
  border-radius: 15px;
  height: 90%;
}

.percentDone {
  color: #4f4f4f;
}

.container-custom2 {
  width: 40vw;
  min-width: 290px !important;
}

.hvr-grow {
  vertical-align: middle;
  -webkit-transform: perspective(0.5px) translateZ(0);
  transform: perspective(0.5px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}


.promotional-card {
  /* background: #0575E6;  
  background: -webkit-linear-gradient(to right, #021B79, #0575E6);  
  background: linear-gradient(to right, #021B79, #0575E6);  */



  
  background: #ee0979;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #ff6a00, #ee0979);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #ff6a00, #ee0979); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */



  




  
  


  /* background: -webkit-linear-gradient(135deg,#6249c5 0%,#9882f3 100%,#9882f3 100%) no-repeat;  
  background: linear-gradient(135deg,#6249c5 0%,#9882f3 100%,#9882f3 100%) no-repeat;  */
  background-size: 200% 200%;

  border-radius: 15px;
  height: 90%;
  animation: gradient 5s ease infinite;
}

.promotional-heading {
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
}

.promotional-subtext {
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.promotional-cta {
  color: #4f4f4f;
  text-align: center;
  margin-bottom: 0;

}

@media screen and (max-height: 650px) {
  .container-custom {
    height: 56vh;
  }
}

@media screen and (max-height: 750px) {
  .container-custom {
    height: 54vh;
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}