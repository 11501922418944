.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 45px;
	background-color: #27ae60;
	transition: all 0.4s ease-in-out;
}

.footer-toggle {
	display: flex;
	justify-content: space-evenly;
	width: fit-content;
	position: relative;
}
